import React, { CSSProperties, ReactElement, ReactNode, useState } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { appConfig } from 'common/appConfig';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import MapBasicControls, {
  MapBasicControlsSettings,
} from 'components/lib/ConnectMap/MapBasicControls';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';
import MapLayerControls from 'components/lib/ConnectMap/MapLayerControls';
import { PositionProvider } from 'components/lib/ConnectMap/useMapPosition';
import useAntThemeToken from 'themes/useAntThemeToken';
import { useConnectAppTheme } from 'themes/useConnectAppTheme';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';

interface Props {
  // top left corner
  actions?: ReactElement;

  // bottom left corner
  filters?: ReactElement;

  // layer controls in the top right corner
  layerControls?: ReactElement;

  // place content here, use hook to get map reference
  children?: ReactNode;

  // to toggle basic control buttons on / off
  basicControls?: Partial<MapBasicControlsSettings>;

  // map container style
  style?: CSSProperties;

  // controlled center
  center?: { lat: number; lng: number };

  // enable or disable scroll wheel, useful when you don't want to break page scrolling for embedded maps
  scrollwheel?: boolean;

  // set to true to display "loading screen" potentially longer than Google Maps takes to load
  loading?: boolean;
}

const ControlLayer = styled.div<{
  $breakpoint: ScreenMap;
  $isFullscreen: boolean;
  $breakGridRight: boolean;
}>`
  margin-left: ${(props) =>
    props.$isFullscreen
      ? '16px'
      : props.$breakpoint.md
        ? props.theme.connect.layout.paddingDesktop
        : props.theme.connect.layout.paddingMobile};
  margin-right: ${(props) =>
    props.$isFullscreen || props.$breakGridRight
      ? '16px'
      : props.$breakpoint.md
        ? props.theme.connect.layout.paddingDesktop
        : props.theme.connect.layout.paddingMobile};

  flex: 1 1 auto;
  height: 100%;
  pointer-events: none; // Invisible div, let mouse events pass to map
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: 16px;
  padding-bottom: 32px;
`;

const ControlCol = styled(Col)`
  pointer-events: all;
`;

const SpinContainer = styled.div`
  background: ${(props) => props.theme.ant.colorPrimary};
`;

const GOOGLE_MAPS_MAP_ID_LIGHT = 'd47caa3e12bc8a84';
const GOOGLE_MAPS_MAP_ID_DARK = 'd2a064889be88da7';
const GOOGLE_MAPS_MAP_ID_LIGHT_CLEAN = 'a847ff7d4f6ebe62';
const GOOGLE_MAPS_MAP_ID_DARK_CLEAN = 'e5918c1e816e6124';

export interface MapPosition extends google.maps.LatLngLiteral {}

export const defaultCenter: MapPosition = {
  lat: 61.906276,
  lng: 5.993076,
};

const ConnectMap: React.FC<Props> = (props) => {
  const {
    children,
    filters,
    actions,
    layerControls,
    basicControls,
    style,
    scrollwheel,
    center,
    loading,
  } = props;
  const { isDarkMode } = useConnectAppTheme();
  const { loadError, isLoaded } = useLoadScript({
    id: 'google-maps-script',
    googleMapsApiKey: appConfig.GOOGLE_MAPS_API_KEY,
    mapIds: [
      GOOGLE_MAPS_MAP_ID_LIGHT,
      GOOGLE_MAPS_MAP_ID_LIGHT_CLEAN,
      GOOGLE_MAPS_MAP_ID_DARK,
      GOOGLE_MAPS_MAP_ID_DARK_CLEAN,
    ],
    preventGoogleFontsLoading: false, // <== weird.. events break if enabled. Looks like a css bug in google maps?
  });
  const token = useAntThemeToken();
  const { screenMap: breakpoint } = useScreenInfo();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [position, setPosition] = useState<GeolocationPosition | undefined>(undefined);
  const [showPoi, setShowPoi] = useState(true);

  if (!isLoaded || loading) {
    return <SpinContainer style={style} />;
  }

  if (loadError) {
    // TODO: nicer error...
    return <pre>{JSON.stringify(loadError, null, 2)}</pre>;
  }

  const handlePositionFound = (position: GeolocationPosition) => {
    setPosition(position);
  };

  const mapId = isDarkMode
    ? showPoi
      ? GOOGLE_MAPS_MAP_ID_DARK
      : GOOGLE_MAPS_MAP_ID_DARK_CLEAN
    : showPoi
      ? GOOGLE_MAPS_MAP_ID_LIGHT
      : GOOGLE_MAPS_MAP_ID_LIGHT_CLEAN;

  // NOTE: Google Maps doesn't support changing mapId on runtime. Changing POI layer causes
  // component to re-render (because of key below)
  // We could refactor from cloud based styles to json based, that works dynamically but is slower to work with?
  // Or keep zoom and center state to minimize "flickering"

  return (
    <GoogleMap
      key={'connect-map-' + mapId}
      mapContainerStyle={{
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
      options={{
        mapId,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        panControl: false,
        zoomControl: false,
        backgroundColor: token.colorPrimary,
        minZoom: 3,
        scrollwheel: scrollwheel,
      }}
      center={center || defaultCenter} // NOTE: will override .setCenter(data) when map is re-rendered
      zoom={4}
    >
      <ControlLayer $breakpoint={breakpoint} $isFullscreen={isFullscreen} $breakGridRight={true}>
        <Row gutter={[16, 16]} justify={'space-between'} align={'top'}>
          <ControlCol style={{ overflow: 'hidden' }}>{actions}</ControlCol>
          <ControlCol>
            <MapLayerControls
              showPoi={showPoi}
              onToggleShowPoi={(checked) => setShowPoi(checked)}
              controls={layerControls}
            />
          </ControlCol>
        </Row>
        <Row gutter={[16, 16]} justify={'space-between'} wrap={false} align={'bottom'}>
          <ControlCol style={{ overflow: 'hidden' }}>{filters}</ControlCol>
          <ControlCol>
            <MapBasicControls
              controls={basicControls}
              onEnterFullscreen={() => setIsFullscreen(true)}
              onExitFullscreen={() => setIsFullscreen(false)}
              onPositionFound={handlePositionFound}
            />
          </ControlCol>
        </Row>
      </ControlLayer>
      <PositionProvider value={position}>{children}</PositionProvider>
    </GoogleMap>
  );
};

export default ConnectMap;
